import * as React from "react"
import { useContext } from "react"
import { graphql } from "gatsby"

import {
  Header,
  UserInput,
  Footer,
  BottomNavbar,
  NoAuth,
  NoAccess,
  Loader,
} from "../components/index"
import { Helmet } from "react-helmet"

// Context
import UserContext from "../context/UserAuth"

const IndexPage = ({ data }) => {
  const { isAuthenticated, isAccess, isLoading } = useContext(UserContext) || {
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
  }

  return (
    <div>
      <Helmet>
        <title>Esteekey - User Input</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        {isLoading && <Loader />}
        {!isAuthenticated ? (
          <NoAuth />
        ) : !isAccess ? (
          <NoAccess />
        ) : (
          <UserInput
            allergies={data?.allergens?.nodes || []}
            types={data?.mealTypes?.nodes || []}
          />
        )}
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query AllAllergens {
    allergens: allSanityAllergen {
      nodes {
        name
        nameRU
      }
    }
    mealTypes: allSanityMealType {
      nodes {
        name
        nameRU
      }
    }
  }
`

export default IndexPage
